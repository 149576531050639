import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  IssuesSection,
  EndorsementsSection,
  SecondaryActionsSection,
  KeyActionsSection,
  BioSection,
  VideoSection,
  ToolkitSection,
} from '@components';
import { getKeyValue } from '@utils';
import {
  KEY_ACTION, SECONDARY_ACTIONS, ISSUES, ENDORSEMENTS, BIO, VIDEO, TOOLKIT, LIGHT, DARK,
} from '@utils/vars';

const HomepageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage,
    disableDonateBar, disableAlertBar, disableSplash, pageUrl, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    homepageSections,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const sectionModules = {
    [ENDORSEMENTS]: EndorsementsSection,
    [BIO]: BioSection,
    [ISSUES]: IssuesSection,
    [KEY_ACTION]: KeyActionsSection,
    [SECONDARY_ACTIONS]: SecondaryActionsSection,
    [VIDEO]: VideoSection,
    [TOOLKIT]: ToolkitSection,
  };

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  const navProps = {
    ...navigation,
    mobNavTheme: LIGHT,
  };

  return (
    <Layout
      location={location}
      navigation={navProps}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={DARK}
      mobNavTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      {homepageSections && homepageSections.map((section, index) => {
        const {
          sectionMedia, sectionTitle, id, sectionType,
          sectionLink, contentReferences, summaryOverride,
          externalLink, internalSectionLink,
        } = section;
        const SectionModule = sectionModules[sectionType];
        const nextModule = homepageSections[index + 1];
        return (
          <React.Fragment key={`${id}-reactfrag`}>
            {SectionModule && (
              <SectionModule
                sectionTitle={sectionTitle}
                sectionLink={sectionLink}
                contentReferences={contentReferences}
                summaryOverride={summaryOverride}
                order={index}
                sectionMedia={sectionMedia}
                nextModuleType={nextModule && nextModule.sectionType}
                externalLink={externalLink}
                key={id}
                donateBarDisclaimer={donateBarDisclaimer}
                internalSectionLink={internalSectionLink}
                locale={locale}
                formLabelLanguageOverrides={formLabelLanguageOverrides}
              />
            )}
          </React.Fragment>
        );
      })}
    </Layout>
  );
};

export default HomepageTemplate;

export const homepageQuery = graphql`
  query HomepageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulHomepage {
          title
          id
          homepageSections {
            sectionTitle
            sectionType
            summaryOverride {
              raw
            }
            id
            sectionMedia {
              description
              id
              fluid(quality: 90, maxWidth: 768) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            sectionLink
            externalLink
            internalSectionLink {
              ... on ContentfulPolicyLanding {
                id
                __typename
                page {
                  pageUrl
                }
                intro {
                  raw
                }
              }
              ... on ContentfulNewsItemEndorsementsLanding {
                page {
                  pageUrl
                }
              }
              ... on ContentfulBiography {
                page {
                  pageUrl
                }
                intro {
                  raw
                }
              }
            }
            contentReferences {
              ... on ContentfulEndorsement {
                id
                __typename
                name
                link
                endorsementType
                logoheadshot {
                  description
                  id
                  fluid(quality: 90, maxWidth: 300) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulGeneralContent {
                __typename
                id
                summary {
                  raw
                }
                page {
                  pageUrl
                }
              }
              ... on ContentfulSocialEmbed {
                __typename
                id
                type
                contentfulid
                caption {
                  raw
                }
                videoThumbnail {
                  description
                  id
                  fluid(quality: 90, maxWidth: 1320) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulForm {
                id
                __typename
                ngpFormName
                formUrl
                formTheme
                formSize
                fastactionNoLogin
                databag
                internalFormTitle
                mobileAutofocus
                hideFormTitle
                hideAdditionalInformationTitle
                formLevelLabelLanguageOverrides {
                  FirstName
                  LastName
                  PostalCode
                  EmailAddress
                  submitForm
                  thankYou
                  title
                }
              }
              ... on ContentfulDonation {
                id
                __typename
                title
                donationLink
                disclaimerOverride
                donationButtons {
                  id
                  display
                  value
                }
                donationButtonsMobile {
                  id
                  display
                  value
                }
              }
              ... on ContentfulKeyIssueArea {
                id
                title
                page {
                  pageUrl
                }
              }
              ... on ContentfulCard {
                id
                externalCallToActionLink
                internalCallToActionLink {
                  pageUrl
                }
                callToActionText
              }
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
